import { Helmet } from 'react-helmet';
import { Box, Container } from '@mui/material';
import { useEffect, useState } from 'react';
import { isAutenticated } from 'src/services/login.service';
import { useNavigate } from 'react-router';
import ServicesList from 'src/components/services/ServicesList';
import { apiGetAllServices, apiToggleServiceState } from 'src/services/service.service';

const ServicesPage = () => {
  const navigate = useNavigate();

  const [services, setServices] = useState([]);

  const loadServices = async () => {
    const servicesResponse = await apiGetAllServices();
    const { data } = servicesResponse;
    setServices([...data]);
  };

  useEffect(() => {
    if (isAutenticated()) {
      loadServices();
    } else {
      navigate('/login');
    }
  }, []);

  const toggleServiceState = (service) => {
    apiToggleServiceState(service.id).then(() => {
      loadServices();
    });
  };

  /**
   * JSX
   */
  return (
    <>
      <Helmet>
        <title>RTM - Services</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 11
        }}
      >
        <Container sx={{ alignItems: 'start' }} maxWidth={false}>
          <Box sx={{ width: '50%', pt: 3 }}>
            <ServicesList data={services} toggleServiceState={toggleServiceState} />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default ServicesPage;
