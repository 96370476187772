import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AppBar, Box, Hidden, IconButton, Toolbar } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import InputIcon from '@mui/icons-material/Input';
import Logo from './Logo';

const DashboardNavbar = ({ onMobileNavOpen, onLogout, ...rest }) => {
  const location = useLocation();

  const NAVBAR_TEXTS = [
    { page: '/', text: '' },
    { page: '/app/users', text: 'Utilisateurs' },
    { page: '/app/flux', text: 'Flux' },
    { page: '/app/lines', text: 'Lignes' },
    { page: '/app/interstitials', text: 'Interstitiels' },
    { page: '/app/hints', text: 'Astuces' },
    { page: '/app/importHistory', text: 'Historique imports' },
    { page: '/app/initBdd', text: 'Init BDD' },
    { page: '/app/services', text: 'Services' }
  ];

  const currentPage = NAVBAR_TEXTS.find((el) => el.page === location.pathname);

  return (
    <AppBar elevation={0} {...rest}>
      <Toolbar className="toolbar">
        <RouterLink className="text-heading" to="/">
          <div className="header-left-inner">
            <div className="boxed">
              <h1 className="logo-heading">
                <Logo />
              </h1>
              <h1 className="text-heading">Régie des Transports Métropolitains</h1>
            </div>
          </div>
        </RouterLink>

        <h2 className="current-page-heading">{currentPage ? currentPage.text : 'Page inconnue'}</h2>
        <Box sx={{ flexGrow: 1 }} />
        <Hidden lgDown>
          <IconButton color="black" onClick={onLogout}>
            <InputIcon />
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton color="black" onClick={onMobileNavOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

DashboardNavbar.propTypes = {
  onMobileNavOpen: PropTypes.func,
  onLogout: PropTypes.func
};

export default DashboardNavbar;
