import { createTheme, colors } from '@mui/material';
import shadows from './shadows';
import typography from './typography';

const theme = createTheme({
  palette: {
    background: {
      default: '#FAFAFA',
      paper: colors.common.white
    },
    primary: {
      contrastText: '#ffffff',
      main: '#5664d2'
    },
    text: {
      primary: '#36474F',
      secondary: '#6b778c'
    },
    alert: {
      contrastText: '#ffffff',
      main: '#C00000'
    }
  },
  shadows,
  typography
});

export default theme;
