import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { CircularProgress, Table, TableBody, TableCell, TableHead, TableRow, Button, Tooltip, Grid } from '@mui/material';

import ToggleOff from '@mui/icons-material/ToggleOff';
import ToggleOn from '@mui/icons-material/ToggleOn';
import { DateTime } from 'luxon';

const ServicesList = ({ data, isRequesting, toggleServiceState }) => {
  /**
   * Format display information
   *
   * @param {*} service
   * @returns
   */
  const formatInformation = (service) => {
    const dateTime = DateTime.fromISO(service.changedAt);

    const dateInfo = `${dateTime.toFormat('dd/MM/yyyy')} à ${dateTime.toFormat('HH:mm')}`;

    return `${service.active ? 'Activé' : 'Désactivé '} par ${service.changeBy} le ${dateInfo}`;
  };

  return (
    <PerfectScrollbar>
      <Grid item lg={8} md={12} xs={12}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="tablecell-green">Service</TableCell>
              <TableCell className="tablecell-green">Information</TableCell>
              <TableCell className="tablecell-green" style={{ width: '50px' }}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          {!isRequesting ? (
            <TableBody>
              {data.map((service) => (
                <TableRow hover key={service.id}>
                  <TableCell>{service.name}</TableCell>
                  <TableCell>{formatInformation(service)}</TableCell>
                  <TableCell>
                    <Tooltip title={service.active ? 'Désactiver' : 'Activer'}>
                      <Button
                        size="64px"
                        startIcon={service.active ? <ToggleOff /> : <ToggleOn />}
                        href="#text-buttons"
                        color={service.active ? 'primary' : 'alert'}
                        onClick={() => toggleServiceState(service)}
                      />
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow hover>
                <TableCell colSpan={11} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </Grid>
    </PerfectScrollbar>
  );
};

ServicesList.propTypes = {
  data: PropTypes.array.isRequired,
  isRequesting: PropTypes.bool,
  toggleServiceState: PropTypes.func
};

export default ServicesList;
